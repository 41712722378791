// アプリケーションで共通で使う関数と発火イベントを記載する

  // ==============================
  // 関数
  // ==============================
  // ⚫︎ 特定のテーブルの項目でソート(昇順・降順)する関数
  function sortTable(columnIndex, tableId) {
      var table, rows, switching, i, x, y, shouldSwitch, dir, switchCount = 0;
      // テーブル要素を取得
      table = document.querySelector(tableId);
      switching = true;
      // 初期のソート方向を昇順に設定
      dir = "asc";
      
      // ソートが完了するまでループ
      while (switching) {
        switching = false;
        // テーブルの行を取得
        rows = table.rows;
        
        // テーブルの各行をループ
        for (i = 1; i < (rows.length - 1); i++) {
          shouldSwitch = false;
          // 比較する2つのセルを取得
          x = rows[i].getElementsByTagName("TD")[columnIndex];
          y = rows[i + 1].getElementsByTagName("TD")[columnIndex];
          
          // 昇順の場合の比較
          if (dir == "asc") {
            if (parseInt(x.innerHTML) > parseInt(y.innerHTML)) {
              shouldSwitch = true;
              break;
            }
          // 降順の場合の比較
          } else if (dir == "desc") {
            if (parseInt(x.innerHTML) < parseInt(y.innerHTML)) {
              shouldSwitch = true;
              break;
            }
          }
        }
        
        // 行を入れ替える必要がある場合
        if (shouldSwitch) {
          rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
          switching = true;
          switchCount++;
        } else {
          // ソートが完了していない場合、方向を変更して再度ソート
          if (switchCount == 0 && dir == "asc") {
            dir = "desc";
            switching = true;
          }
        }
      }

      // アイコンの色を変更
      var thElements = table.querySelectorAll('th');
      thElements.forEach(function(th, index) {
        var upIcon = th.querySelector('.fa-sort-up');
        var downIcon = th.querySelector('.fa-sort-down');
        if (upIcon && downIcon) { // アイコンが存在するかチェック
          if (index === columnIndex) {
            if (dir === "asc") {
              upIcon.style.color = "#757575";
              downIcon.style.color = "#cfcfcf";
            } else {
              upIcon.style.color = "#cfcfcf";
              downIcon.style.color = "#757575";
            }
          } else {
            upIcon.style.color = "#cfcfcf";
            downIcon.style.color = "#cfcfcf";
          }
        }
      });
    }
  // ==============================
  // イベント発火
  // ==============================
  $(document).on('turbolinks:load', function () {
    // テーブルの項目でソート(昇順・降順)する
    $('#result-by-players-list table th').on('click', function() {
      // クリックされた列のインデックスを取得
      var columnIndex = this.cellIndex;
      // テーブルIDを設定
      var tableId = "#result-by-players-list table";
      // ソート関数を呼び出し
      sortTable(columnIndex, tableId);
  });
});